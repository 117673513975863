.home-bg {
    height: 100vh;
    background-color: unset;
}

.home-header {
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(150,150,150,0.5);
    z-index: 10;
}

.home-icon {
    width: 40px;
    border-radius: 20%;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
}

.home-title-subtitle {
    float: left;
}

.home-title {
    margin-top: 3px;
    line-height: 40px;
    font-weight: bold;
}

.home-subtitle {
    line-height: 10px;
    font-size: 10px;
    font-weight: lighter;
    font-style: italic;
    margin-top: -10px;
}

.home-out-card {
    height: 85vh;
    position: relative;
}

.home-in-card {
    max-height: 100%;
    overflow: auto;
}

.home-list-add-button {
    margin-bottom: 20px;
    margin-top: 20px;
}