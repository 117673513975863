.login-bg-image {
    width: 100%;
    height: 100vh;
    background-image: url('/src/assets/img/room-bg.png');
    background-size: cover;
}

.login-bg {
    width: 100%;
    height: 100vh;
    background: rgba(35, 58, 74, 0.9);
}

.login-box {
    width: 400px;
    position: absolute;
    top: 35%;
    left: 50%;
    margin: -100px 0px 0px -150px;
}

.login-form-elements {
    margin-bottom: 10px;
    width: 100%;
}

.login-subtitle {
    font-size: 12px;
    font-weight: lighter;
}

.login-icon {
    width: 60px;
    border-radius: 20%;
    margin-bottom: 20px;
}

.login-align-center {
    text-align: center;
}