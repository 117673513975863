@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
/* @import 'react-credit-cards/es/styles-compiled.css'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: linear-gradient(#ffd633, #ffeea9); */
  background-color: #233a4a;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-bg-image {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/room-bg.4d6b1e36.png);
    background-size: cover;
}

.login-bg {
    width: 100%;
    height: 100vh;
    background: rgba(35, 58, 74, 0.9);
}

.login-box {
    width: 400px;
    position: absolute;
    top: 35%;
    left: 50%;
    margin: -100px 0px 0px -150px;
}

.login-form-elements {
    margin-bottom: 10px;
    width: 100%;
}

.login-subtitle {
    font-size: 12px;
    font-weight: lighter;
}

.login-icon {
    width: 60px;
    border-radius: 20%;
    margin-bottom: 20px;
}

.login-align-center {
    text-align: center;
}
.home-bg {
    height: 100vh;
    background-color: unset;
}

.home-header {
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(150,150,150,0.5);
    z-index: 10;
}

.home-icon {
    width: 40px;
    border-radius: 20%;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
}

.home-title-subtitle {
    float: left;
}

.home-title {
    margin-top: 3px;
    line-height: 40px;
    font-weight: bold;
}

.home-subtitle {
    line-height: 10px;
    font-size: 10px;
    font-weight: lighter;
    font-style: italic;
    margin-top: -10px;
}

.home-out-card {
    height: 85vh;
    position: relative;
}

.home-in-card {
    max-height: 100%;
    overflow: auto;
}

.home-list-add-button {
    margin-bottom: 20px;
    margin-top: 20px;
}








input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}





input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}











.user-details-modal-view-tag {
    cursor: pointer;
    color: rgb(85, 85, 85);
}

.user-details-modal-usertype-tag {
    font-size: smaller;
    font-weight: lighter;
    font-style: italic;
}

.user-details-modal-ok-button {
    margin-left: 20px;
}
.user-list-print-button {
    width: 100%;
}



.reports-bg-card {
    background-color: white;
    height: 85vh;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    overflow-y: scroll;
}

.reports-title {
    font-size: larger;
    font-weight: bold;
}

.reports-button {
    margin-top: 30px;
}

.reports-chart-title {
    font-size: 16px;
    font-family: Roboto;
    color: rgb(117, 117, 117);
}

.reports-chart-subtitle {
    font-size: 14px;
    font-family: Roboto;
    color: rgb(189, 189, 189);
    margin-bottom: 20px;
}
.configSchedule-bg-card {
    background-color: white;
    height: 85vh;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    overflow-y: scroll;
}

.configSchedule-title {
    font-size: larger;
    font-weight: bold;
}

.configSchedule-button {
    margin-top: 30px;
}


.csat-button {
    width: 100%;
}
.premiumBody {
    position: relative;
    height: 100vh;
    background: white;
}
.premiumTopBar {
    background-color: #375266;
    height: 15vh;
}
.premiumTopBarTextDiv {
    padding-left: 10px;
    padding-top: 10vh;
    font-size: large;
    color: gray;
}
.premiumMiddle {
    background-color: white;
}
.premiumMiddleTextDiv {
    font-size: xx-large;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    line-height: 35px;
}
.premiumSubscriptionCard {
    width: 90%;
    max-width: 400px;
    margin: auto;
    background-color: white;
    border-radius: 1vh;
}
.premiumSupportText {
    /* font-weight: bold; */
    /* font-family: 'Poiret One', cursive; */
    /* color: #233a4a; */
    margin: 10px;
    font-size: x-small;
}
.premiumPriceText {
    font-weight: bold;
    margin-left: 10px;
    margin-top: 20px;
    font-size: x-large;
}
.premiumIcon {
    width: 15vh;
    border-radius: 20%;
    margin-left: 10px;
    margin-top: 5vh;
}

.premiumHeader {
    padding: 15px;
}

.premiumTitle {
    margin-bottom: 0;
    line-height: 20px;
    color: gray;
    font-size: small;
    font-family: 'Poiret One', cursive;
}

.premiumSubTitle {
    font-weight: bold;
    margin-top: 0;
    font-size: large;
    line-height: 20px;
    font-family: 'Poiret One', cursive;
}

/* .premiumBody {
    background-color: white;
    padding: 10px 0px;
    min-height: 100vh;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px 20px 0px 0px;
}

.premiumTitleBox {
    float: left;
    clear: both;
}


.premiumCardInfo {
    margin: 20px 10px;
}

.premiumCardField {
    color: white;
} */
.user-list-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
  
.user-list-table-td, .user-list-table-th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
