.reports-bg-card {
    background-color: white;
    height: 85vh;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    overflow-y: scroll;
}

.reports-title {
    font-size: larger;
    font-weight: bold;
}

.reports-button {
    margin-top: 30px;
}

.reports-chart-title {
    font-size: 16px;
    font-family: Roboto;
    color: rgb(117, 117, 117);
}

.reports-chart-subtitle {
    font-size: 14px;
    font-family: Roboto;
    color: rgb(189, 189, 189);
    margin-bottom: 20px;
}