.user-list-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
  
.user-list-table-td, .user-list-table-th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}